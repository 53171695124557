import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { icons } from './icons';

@Component({
  selector: 'Icon',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './icon.component.html',
  styleUrl: './icon.component.scss',
})
export class IconComponent implements OnInit {
  @Input({ required: true }) src!: keyof typeof icons;
  @Input() size!: string;
  @Input() class!: string;

  sizeStyle!: string;

  path = 'assets/images/icons/svg/';

  icons = icons;

  ngOnInit(): void {
    this.setSize();
  }

  setSize() {
    switch (this.size) {
      case 'xs':
        this.sizeStyle = '1em';
        break;
      case 'sm':
        this.sizeStyle = '1.5em';
        break;
      case 'md':
        this.sizeStyle = '2em';
        break;
      case 'lg':
        this.sizeStyle = '3em';
        break;
      case 'xl':
        this.sizeStyle = '4em';
        break;
      case '2xl':
        this.sizeStyle = '6em';
        break;
      default:
        this.sizeStyle = '2em';
        break;
    }
  }
}
