// icones validos do componentes nome = nome-arquivo

export enum icons {
  photos = 'photos',
  vip = 'vip',
  checked = 'checked',
  garage = 'garage',
  me = 'me',
  doorExit = 'door-exit',
  sun = 'sun',
  moon = 'moon',
  code = 'code',
  school = 'school',
  customer = 'customer',
  carTag = 'car-tag',
  agreement = 'agreement',
  signature = 'signature',
  moneys = 'moneys',
  buildings = 'buildings',
  vehicleModel = 'vehicle-model',
  suv = 'suv',
  truck = 'truck',
  moto = 'moto',
  reports = 'reports',
  menu = 'menu',
  sortDown = 'sort-down',
  sortUp = 'sort-up',
  sort = 'sort',
  filter = 'filter',
  users = 'users',
  carPending = 'car-pending',
  carBreakdown = 'car-breakdown',
  notIconValid = 'not-icon-valid',
  cash = 'cash',
  calendarDatetime = 'calendar-datetime',
  calendarDelete = 'calendar-delete',
  calendar = 'calendar',
  checklist = 'checklist',
  searchCar = 'search-car',
  searchDelete = 'search-delete',
  search = 'search',
  car = 'car',
  parkedCar = 'parked-car',
  arrowDown = 'arrow-down',
  arrowUp = 'arrow-up',
  vehicleManager = 'vehicle-manager',
  deviceManager = 'device-manager',
  conference = 'conference',
  myData = 'my-data',
  admin = 'admin',
  close = 'close',
  sync = 'sync',
  searchManager = 'search-manager',
  userEdit = 'user-edit',
  resetPassword = 'reset-password',
  trash = 'trash',
  userPlus = 'user-plus',
  edit = 'edit',
  parkingCar = 'parking-car',
  change = 'change',
  moneyChange = 'money-change',
  moneyUp = 'money-up',
  discount = 'discount',
  eye = 'eye',
  eyeClose = 'eye-close',
}
