import { Injectable } from '@angular/core';
import * as Notiflix from 'notiflix';

@Injectable({
  providedIn: 'root',
})
export class NotiflixService {
  constructor() {}

  notify(important = false) {
    let position: any = 'left-bottom';
    let cssAnimationStyle: any = 'from-left';
    let closeButton: boolean = false;

    if (important) {
      position = 'center-top';
      cssAnimationStyle = 'from-top';
      closeButton = true;
    }

    Notiflix.Notify.init({
      clickToClose: true,
      closeButton: closeButton,
      position: position,
      cssAnimationStyle: cssAnimationStyle,
      distance: '10px',
      cssAnimation: true,
      cssAnimationDuration: 400,
      opacity: 1,
      showOnlyTheLastOne: true,
      plainText: false,
      messageMaxLength: 270,
      zindex:19999,
      success: {
        background: '#32c682',
        textColor: '#fff',
        childClassName: 'notiflix-notify-success',
        notiflixIconColor: 'rgba(0,0,0,0.2)',
        fontAwesomeClassName: 'fas fa-check-circle',
        fontAwesomeIconColor: 'rgba(0,0,0,0.2)',
        backOverlayColor: 'rgba(0,0,0,0.2)',
      },
      failure: {
        background: '#ff5549',
        textColor: '#fff',
        childClassName: 'notiflix-notify-failure',
        notiflixIconColor: 'rgba(0,0,0,0.2)',
        fontAwesomeClassName: 'fas fa-times-circle',
        fontAwesomeIconColor: 'rgba(0,0,0,0.2)',
        backOverlayColor: 'rgba(255,85,73,0.2)',
      },
      warning: {
        background: '#a17703',
        textColor: '#fff',
        childClassName: 'notiflix-notify-warning',
        notiflixIconColor: 'rgba(0,0,0,0.2)',
        fontAwesomeClassName: 'fas fa-exclamation-circle',
        fontAwesomeIconColor: 'rgba(0,0,0,0.2)',
        backOverlayColor: 'rgba(238,191,49,0.2)',
      },
      info: {
        background: '#26c0d3',
        textColor: '#fff',
        childClassName: 'notiflix-notify-info',
        notiflixIconColor: 'rgba(0,0,0,0.2)',
        fontAwesomeClassName: 'fas fa-info-circle',
        fontAwesomeIconColor: 'rgba(0,0,0,0.2)',
        backOverlayColor: 'rgba(38,192,211,0.2)',
      },
    });

    return this;
  }

  loading(message: string = '') {
    Notiflix.Loading.init({
      svgColor: '#2444e3',
      zindex:19999,
    });
    Notiflix.Loading.dots(message);
  }

  removeLoading() {
    Notiflix.Loading.remove();
  }

  block(element: string) {
    Notiflix.Block.dots(element);
  }

  removeBlock(element: string) {
    Notiflix.Block.remove(element);
  }

  confirm(
    title: string,
    message: string,
    labelYes: string = 'Sim',
    labelNo: string = 'Cancelar',
    callbackYes: any,
    callbackNo?: any,
  ) {
    const theme = 'default';

    Notiflix.Confirm.init({
      width: '320px',
      messageMaxLength: 1923,
      plainText: false,
      borderRadius: '5px',
      backgroundColor: 'white',
      messageColor: '#1b1b1c',
      titleColor: '#1b1b1c',
      titleFontSize: '13px',
      titleMaxLength: 50,
      okButtonColor: 'white',
      okButtonBackground: '#2321ad',
      cancelButtonColor: 'white',
      cancelButtonBackground: '#9c9c9c',
      zindex:19999,
    });

    Notiflix.Confirm.show(
      title,
      message,
      labelYes,
      labelNo,
      function okCb() {
        callbackYes();
      },
      function cancelCb() {
        callbackNo();
      }
    );
  }

  report(
    title: string,
    message: string,
    label: string = 'OK',
    callbackOptions: any = undefined,
  ) {
    Notiflix.Report.init({
      width: '320px',
      messageMaxLength: 1923,
      plainText: false,
      borderRadius: '5px',
      backgroundColor: 'white',
      zindex:19999,

      titleFontSize: '13px',
      titleMaxLength: 50,

      success: {
        messageColor: '#1b1b1c',
        buttonColor: '#fff',
        titleColor: '#1b1b1c',
      },
    });

    Notiflix.Report.success(title, message, label, callbackOptions);
  }

  success(message: string, callbackOptions: any = undefined) {
    Notiflix.Notify.success(message, callbackOptions);
  }

  info(message: string, callbackOptions: any = undefined) {
    Notiflix.Notify.info(message, callbackOptions);
  }

  warning(message: string, callbackOptions: any = undefined) {
    Notiflix.Notify.warning(message, callbackOptions);
  }

  danger(message: string, callbackOptions: any = undefined) {
    Notiflix.Notify.failure(message, callbackOptions);
  }
}
